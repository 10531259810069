import React from 'react';
import image from '../../images/landmark.jpg';
import image2 from '../../images/location1.jpeg';
import image3 from '../../images/location2.jpeg';
import v3 from '../../images/v3.jpeg';

const About = () => {
  return (
    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div
        className="border-green-600 font-medium text-2xl p-3 mb-3 text-900"
        style={{ borderLeft: '8px solid' }}
      >
        Ecolite Minerals Kenya
      </div>
      <div className="line-height-3 text-lg text-700 mb-4">
        Ecolite Minerals Limited is a private limited company incorporated in
        Kenya that has set up a vermiculite exfoliation plant in Naivasha at the
        Flower Business Park. We are the largest distributor and exfoliation
        plant of vermiculite in East Africa. We are dedicated to popularise the
        use of vermiculite among other substitutes for use in the Agriculture,
        Floriculture, Steel, Insulation, Fireproofing and Animal Feeds
        industries.
      </div>
      <blockquote className="line-height-3 text-xl text-600 mb-4 py-0 mx-0 px-4">
        &quot;We aim to make growing medium products available and cheap to the
        local and international markets&quot;
      </blockquote>
      <div className="line-height-3 text-lg text-700 mb-4">
        Ecolite Minerals Ltd aims to develop the Vermiculite market in East
        Africa by establishing a group of companies where the existing skills
        and resources can be shared across a single platform to favor the
        emergence of the ‘best in class’ approach and to promote synergies and
        the thriving of new ideas for the creation of long term value for all of
        the stakeholders.
      </div>
      <div className="text-center mb-4">
        <div
          className="uk-position-relative uk-visible-toggle uk-light"
          tabIndex="-1"
          uk-slideshow="ratio: false;finite: true"
        >
          <ul
            className="uk-slideshow-items"
            uk-height-viewport="offset-top: true; offset-bottom: 30"
          >
            <li>
              <img src={image2} alt="" uk-cover="true" />
            </li>
            <li>
              <img src={image3} alt="" uk-cover="true" />
            </li>
          </ul>

          <a
            className="uk-position-center-left uk-position-small uk-hidden-hover bg-green-600 p-3"
            href="#"
            uk-slidenav-previous="true"
            uk-slideshow-item="previous"
          ></a>
          <a
            className="uk-position-center-right uk-position-small uk-hidden-hover bg-green-600 p-3"
            href="#"
            uk-slidenav-next="true"
            uk-slideshow-item="next"
          ></a>
        </div>
        <span className="block text-700 line-height-3 mt-2">
          Ecolite Minerals, Naivasha, Kenya
        </span>
      </div>
      <div className="line-height-3 text-lg text-700 mb-4">
        Vermiculite is a multi-purpose mineral that has various applications
        across different industries including agriculture, construction and
        industrial fields.
      </div>
      <div className="text-center mb-4">
        <img src={v3} alt="content-1" className="w-full border-round" />
        <span className="block text-700 line-height-3 mt-2">
          Vermiculite and Perlite Packaging
        </span>
      </div>
      <div className="line-height-3 text-lg text-700 mb-4">
        Our corporate culture is based on work ethic, professional behavior,
        endurance, transparency in communication, partnership spirit and a
        compassionate and inclusive relation with the local community which is
        the backbone of any project.
      </div>
      <div className="text-center mb-4">
        <img src={image} alt="content-1" className="w-full border-round" />
        <span className="block text-700 line-height-3 mt-2">
          Flower Business Park, Naivasha, Kenya
        </span>
      </div>
    </div>
  );
};

export default About;
