import * as React from 'react';

import { connect } from 'react-redux';
import About from '../components/about/About';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="About" />
      <About />
      <Footer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(AboutPage);
